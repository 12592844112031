import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import AccentBox from "components/accentBox";
import { Link, graphql } from "gatsby";
import Card from "components/card";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "which"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/pcselection/note-desktop.jpg"}) { ...eyecatchImg },
    celectricbill: file(relativePath: { eq: "cover/pcselection/electricbill.png"}) { ...eyecatchImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`ノートかデスクトップかどちらを選択？`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="ノートかデスクトップか" mdxType="Image" />
    <p>{`外出先で使いたい場合はノートパソコン一択であるが、デスクトップはモニタやキーボードが大きく快適で、使い勝手が良さそう。`}</p>
    <p>{`市場のシェアとしてはノートパソコンが４に対してデスクトップが１程度だが、生産性の高さではデスクトップに軍配が上がる。`}</p>
    <p>{`本ページではまずそれぞれの特徴を詳しく述べ、`}<strong parentName="p">{`メリット・デメリットを整理`}</strong>{`する。整理の後、`}<strong parentName="p">{`２台持ち`}</strong>{`、`}<strong parentName="p">{`ノートパソコンのデスクトップ化`}</strong>{`など視点を変えた方法を合わせ、後悔のない判断ができるようになることを目指す。`}</p>
    <p>{`具体的なスペック等も含めておすすめのパソコンを調べたい場合は`}<a href="/">{`パソコン選び方ガイドトップのパソコン診断`}</a>{`も利用してみよう。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "携帯性のノートパソコン、快適性のデスクトップパソコン",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%90%BA%E5%B8%AF%E6%80%A7%E3%81%AE%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%80%81%E5%BF%AB%E9%81%A9%E6%80%A7%E3%81%AE%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3",
        "aria-label": "携帯性のノートパソコン、快適性のデスクトップパソコン permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`携帯性のノートパソコン、快適性のデスクトップパソコン`}</h2>
    <p>{`ノートパソコン購入の最大の利点は言うまでもなく`}<strong parentName="p">{`携帯性`}</strong>{`である。`}</p>
    <p>{`家ではソファーや食卓などの好きな場所でパソコンを使う事ができ、Wifiを用いると、LANケーブル無しで家中インターネットへアクセスすることもできる。`}</p>
    <p>{`または外にパソコンを持ち出して図書館やカフェで仕事の資料作成を行う事もできるし、実家に帰省するタイミングでも重宝する。`}</p>
    <p>{`一方でデスクトップパソコンは`}<strong parentName="p">{`快適性でノートパソコンを上回る`}</strong>{`。`}</p>
    <p>{`キーボードとマウスが本体と分離されているため自分が好きな場所に調整して使う事ができる。好きな姿勢で作業ができるのは利点である。`}</p>
    <p>{`キーボード自体もノートパソコンに比べると大きいサイズになっており、更に打ちやすいように斜めに傾いているキーボードも多い。`}</p>
    <p>{`ノートパソコンの小さな平らなキーボードで長時間作業しているとどうしても窮屈な姿勢を取らざるを得なくなり肩が凝る。さらに画面が小さいため、少ない画面表示領域に何を表示させるべきかで悩む。`}</p>
    <p>{`これらから、`}<strong parentName="p">{`いつも同じ場所で作業を行える状態にある人だとデスクトップパソコンの方が快適性で優れており、外でも使う必要がある場合や、家の中でデスクトップを置く場所がない場合はノートパソコンが良い`}</strong>{`と言える。`}</p>
    <p>{`次はその他の重要な点を整理してみる。`}</p>
    <h2 {...{
      "id": "携帯性以外のノートパソコンの利点",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%90%BA%E5%B8%AF%E6%80%A7%E4%BB%A5%E5%A4%96%E3%81%AE%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E5%88%A9%E7%82%B9",
        "aria-label": "携帯性以外のノートパソコンの利点 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`携帯性以外のノートパソコンの利点`}</h2>
    <h3 {...{
      "id": "電気代が安い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E9%9B%BB%E6%B0%97%E4%BB%A3%E3%81%8C%E5%AE%89%E3%81%84",
        "aria-label": "電気代が安い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`電気代が安い`}</h3>
    <p>{`ノートパソコンは低消費電力になるように設計されたCPUを搭載しているため、あまり電力が掛からない。一つの目安として、`}<strong parentName="p">{`毎日3時間程度インターネットやワード等普通にパソコンを使う人は月100円程度ノートパソコンの方が安くなる`}</strong>{`
。デスクトップが月130円、ノートが月30円位だと思って貰えれば良い。`}</p>
    <p>{`特に、仕事用など長時間パソコンを使う人、ゲームや重い処理の計算を多く行う人の場合は5年間で1万円以上の電気代が変わってくるので検討要素に加えるべきであろう。`}</p>
    <p>{`より詳しく感覚を掴みたい人は以下の記事を参考にしてみて欲しい。`}</p>
    <Card {...props} title="パソコンの電気代を徹底検証" description="ノート、デスクトップ、ゲーミングパソコンのおよその電気代を知ることができます。" image="celectricbill" path="/electricbill/" mdxType="Card" />
    <h3 {...{
      "id": "ケーブルが電源だけで良く見た目がスッキリ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B1%E3%83%BC%E3%83%96%E3%83%AB%E3%81%8C%E9%9B%BB%E6%BA%90%E3%81%A0%E3%81%91%E3%81%A7%E8%89%AF%E3%81%8F%E8%A6%8B%E3%81%9F%E7%9B%AE%E3%81%8C%E3%82%B9%E3%83%83%E3%82%AD%E3%83%AA",
        "aria-label": "ケーブルが電源だけで良く見た目がスッキリ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ケーブルが電源だけで良く見た目がスッキリ`}</h3>
    <p>{`ノートパソコンはモニタ、キーボードが本体と一体となっているため見た目がスッキリし、使わなくなった場合には本棚にでも収納しておけば邪魔にもならない。`}</p>
    <p>{`一方で、デスクトップパソコンは物理的に大きい上に配線も面倒である。`}</p>
    <p>{`たとえば、モニタとPCが分離しているため、繋ぐためのコードが必要となり、それぞれに対して電源ケーブルが必要となる。つまり最低でもケーブルは３つある。`}</p>
    <p>{`これにマウスとキーボードをUSBケーブルでつなぐことになれば５つになり、インターネットをするためのケーブルを挿せば６つになる。`}</p>
    <p>{`よってスッキリしないうえ、場所移動にしても、収納にしても大変である。`}</p>
    <h3 {...{
      "id": "売りやすい",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%A3%B2%E3%82%8A%E3%82%84%E3%81%99%E3%81%84",
        "aria-label": "売りやすい permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`売りやすい`}</h3>
    <p>{`不要になった折には中古として売りに出す事もできるが、`}<strong parentName="p">{`持ち込みが容易なため処分が比較的簡単`}</strong>{`に行える。もちろんデスクトップPCも売ることができるが、出張買取となると値段がつかない場合も多い。`}</p>
    <p>{`メルカリやラクマなどのフリマアプリでも、梱包の手間や手数料の安さを考えると、売りやすのは断然ノートパソコンである。`}</p>
    <h2 {...{
      "id": "快適性以外のデスクトップパソコンの利点",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%BF%AB%E9%81%A9%E6%80%A7%E4%BB%A5%E5%A4%96%E3%81%AE%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E5%88%A9%E7%82%B9",
        "aria-label": "快適性以外のデスクトップパソコンの利点 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`快適性以外のデスクトップパソコンの利点`}</h2>
    <h3 {...{
      "id": "パフォーマンスが高い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%91%E3%83%95%E3%82%A9%E3%83%BC%E3%83%9E%E3%83%B3%E3%82%B9%E3%81%8C%E9%AB%98%E3%81%84",
        "aria-label": "パフォーマンスが高い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`パフォーマンスが高い`}</h3>
    <p>{`省電力設計がなされているモバイルPCに比べ、デスクトップのPCはサクサク動くことが多い。筐体が大きく、冷却性能にも優れているため熱がこもりCPU性能が低下してファーという音がする事も少ない。`}</p>
    <p>{`パソコンの性能を測るテストをベンチマークテストと呼ぶが、おおよそ`}<strong parentName="p">{`デスクトップパソコンのCore i3 CPUが、ノートパソコンのCore i7を上回る`}</strong>{`スコアを叩きだす。`}</p>
    <p>{`同じCore i3の場合、ただWebブラウジングをしているだけであっても体感でハッキリと違いが分かる。詳しくは関連リンクのベンチマークの欄を参考にして欲しい。`}</p>
    <p>{`ただ、注意点として、`}<strong parentName="p">{`省スペース向けの一体型デスクトップパソコンはノートと同じ低消費電力のCPUを搭載`}</strong>{`しているため、パフォーマンスの恩恵は受けることができない。`}</p>
    <p>{`見分け方としてはCore i5 8250Uなど、お尻にUがついていると低消費電力版のCPUであることが分かる。`}</p>
    <SmallBox type="link" mdxType="SmallBox">
  <Link to="/cpu/" mdxType="Link">CPU性能比較表</Link>
    </SmallBox>
    <h3 {...{
      "id": "拡張性が高い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%8B%A1%E5%BC%B5%E6%80%A7%E3%81%8C%E9%AB%98%E3%81%84",
        "aria-label": "拡張性が高い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`拡張性が高い`}</h3>
    <p>{`筐体の大きさにもよるが、拡張性が高く、メモリの増設やグラフィックボードの付け替え、ハードディスクの増設などが行えることがデスクトップの魅力である。`}</p>
    <p>{`キーボードが気に入らなければそこだけ買う事もできるが、ノートだとセットになっているためそういう訳にも行かない。`}</p>
    <p>{`高度なゲームをしたくなった時、デスクトップパソコンならばグラフィックボードを買い増して取り付ければ良い。`}<strong parentName="p">{`最初から全てを揃えておかなくても順次対応できる`}</strong>{`事がデスクトップパソコンの魅力の一つである。`}</p>
    <p>{`ただし、パソコン本体のサイズや構造によってどこまで拡張できるかが決まってくるためそこは注意しなければならない。タワー型と呼ばれる大きめの筐体ならば基本何でも拡張が可能だ。`}</p>
    <SmallBox type="link" mdxType="SmallBox">
  <Link to="/extention/" mdxType="Link">パソコンの拡張性とは？パソコン購入時に考えるべき拡張性の勘所</Link>
    </SmallBox>
    <h3 {...{
      "id": "買い替えコストが安い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E8%B2%B7%E3%81%84%E6%9B%BF%E3%81%88%E3%82%B3%E3%82%B9%E3%83%88%E3%81%8C%E5%AE%89%E3%81%84",
        "aria-label": "買い替えコストが安い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`買い替えコストが安い`}</h3>
    <p>{`拡張性の高さと関係するが、モニターと本体が分離されているため、`}<strong parentName="p">{`パソコンの買い替えでモニターを新たに購入する必要がない`}</strong>{`。その分だけ安く新しいものを購入できる。`}</p>
    <p>{`また、メモリやグラフィックボードなども幾つか使いまわすことができる。`}</p>
    <p>{`ただしメモリはDDR4などメモリーモジュールの規格の問題があり、規格の異なる古いパソコンのメモリは使えない。またグラフィックボードも進歩が激しく、買い替える頃にはもの足りなくなっているかもしれない。`}</p>
    <p>{`実際私も使いまわした事はほとんどない。しかし古いグラフィックボードはメルカリで半額程度で売却することができた。パーツごとに切り売りできる点は魅力的である。`}</p>
    <h3 {...{
      "id": "修理代が安い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E4%BF%AE%E7%90%86%E4%BB%A3%E3%81%8C%E5%AE%89%E3%81%84",
        "aria-label": "修理代が安い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`修理代が安い`}</h3>
    <p>{`デスクトップパソコンは分解が簡単であるため、修理の手間が少なくて良い。筐体も大きく部品ごとがあまり入り組んでおらず部品交換の作業も容易である。`}</p>
    <p>{`また液晶モニターやキーボードが破損した場合、デスクトップだと代替品を購入し自分で取り替えれば良いのに対し、ノートだと修理に出して交換してもらう必要がある。`}</p>
    <p>{`ノートパソコンは持ち運ぶものであり、満員電車での圧力や、歩行時の細かな振動によりパソコンに負荷がかかるため、`}<strong parentName="p">{`故障率自体も高め`}</strong>{`である。`}</p>
    <h2 {...{
      "id": "ノートとデスクトップのメリットまとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%8E%E3%83%BC%E3%83%88%E3%81%A8%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%E3%81%AE%E3%83%A1%E3%83%AA%E3%83%83%E3%83%88%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "ノートとデスクトップのメリットまとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ノートとデスクトップのメリットまとめ`}</h2>
    <p>{`最後にそれぞれのメリットを箇条書きとしてまとめておく。まず何を優先すべきかを考え、両者を比較検討してみて欲しい。`}</p>
    <AccentBox title="ノートパソコンのメリット" mdxType="AccentBox">
  <li>携帯性が高い・・・言うまでもない最大のメリット</li>
  <li>電気代が安い・・・１日５時間使用で年間約3000円お得</li>
  <li>処分が容易・・・サイズ的に処置に困らない</li>
    </AccentBox>
    <AccentBox title="デスクトップのメリット" mdxType="AccentBox">
  <li>快適性が高い・・・キーボードと本体が分離、無理な体勢を強いられない。モニターも大きい。</li>
  <li>コスパが高い・・・電源と排熱処理に優れフルパワーな処理が可能</li>
  <li>拡張性が高い・・・メモリ、グラボ等を必要に応じて増設</li>
  <li>買替が安い・・・少なくともモニターはそのまま使える</li>
  <li>修理費が安い・・・パーツ分離のメリット、壊れた箇所を替えれば良い</li>
    </AccentBox>
    <p>{`ただ、それぞれ捨てがたいメリットを持っているため決め切れないことも多々あるかと思われる。そこで２台持ちも含め幾つか選択肢を提示してみる。`}</p>
    <h2 {...{
      "id": "予算があればデスクトップとノートの２台持ちもあり",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E4%BA%88%E7%AE%97%E3%81%8C%E3%81%82%E3%82%8C%E3%81%B0%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%E3%81%A8%E3%83%8E%E3%83%BC%E3%83%88%E3%81%AE%EF%BC%92%E5%8F%B0%E6%8C%81%E3%81%A1%E3%82%82%E3%81%82%E3%82%8A",
        "aria-label": "予算があればデスクトップとノートの２台持ちもあり permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`予算があればデスクトップとノートの２台持ちもあり`}</h2>
    <p>{`普段家で作業する時はデスクトップを用い、気分転換に外で作業をしたくなった時はノートパソコンを用いるといった具合に使い分けるのも良い。`}</p>
    <p>{`Webブラウジングや文章の編集など軽い作業ならば５万円以下の安価なノートPCでも快適に行えるので、`}<strong parentName="p">{`重い作業はデスクトップを使い、軽い作業は外出してノートで行うという棲み分けをする`}</strong>{`のもありである。`}</p>
    <p>{`データの共有はUSBで受け渡せば特に問題はない、Wifiの使える環境ならばDropBoxやGoogleDriveでデータの共有をしても良い。`}</p>
    <p>{`一例として、管理人が大学時代に一番最初に購入したパソコンは、下宿で場所がなかったためにモニターと本体の一体型のパソコン(オールインワンパソコン)であった。`}</p>
    <p>{`最初はそれで満足していたのだが、家の中でレポートを書くよりも大学の図書館に行き作業するのも悪くはないと思い、たまに使用する目的でノートPCを購入。ここでのノートPCはそこまで利用頻度は多くないためAtomという低スペックのパソコンを当時は保有していたが十分であった。`}</p>
    <h2 {...{
      "id": "ハイスペックノートパソコンをデスクトップとしても活用",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%8F%E3%82%A4%E3%82%B9%E3%83%9A%E3%83%83%E3%82%AF%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%82%92%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%E3%81%A8%E3%81%97%E3%81%A6%E3%82%82%E6%B4%BB%E7%94%A8",
        "aria-label": "ハイスペックノートパソコンをデスクトップとしても活用 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ハイスペックノートパソコンをデスクトップとしても活用`}</h2>
    <p>{`それぞれのメリットを享受するためにデスクトップとノートの２台持ちも良いが、`}<strong parentName="p">{`ハイスペックなノートパソコンをデスクトップ化して活用する方法`}</strong>{`もおすすめである。`}</p>
    <p>{`デスクトップ化とは具体的にはキーボードやマウス、モニタを別途用意し、ノートパソコンから接続して使うことを言う。`}</p>
    <p>{`それぞれをノートパソコンに接続しても良いが、`}<strong parentName="p">{`よりスマートな方法としてはドッキングステーションを使用`}</strong>{`する。`}</p>
    <p>{`ドッキングステーションはモニタを含む周辺機器を多く取り付けられるハブ商品であり、ノートパソコンがType-Cに対応していれば接続して機能を拡張できる。`}</p>
    <a href="https://www.amazon.co.jp/dp/B07YHJV74T?_encoding=UTF8&pd_rd_i=B07YHJV74T&pd_rd_w=L9cXA&pf_rd_p=36dea1d5-dd4a-4c9f-baca-d2c0b0542db8&pf_rd_r=QYRWQNYQH3WD5MG1QHWZ&pd_rd_r=6ab7c744-7a2e-439f-9d4b-934f5b4307ff&pd_rd_wg=GcYtY&linkCode=li3&tag=daigakuseitus-22&linkId=afe122dfb1175f1a5e766571e14c8442&language=ja_JP&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-fe.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07YHJV74T&Format=_SL250_&ID=AsinImage&MarketPlace=JP&ServiceVersion=20070822&WS=1&tag=daigakuseitus-22&language=ja_JP" /></a><img src="https://ir-jp.amazon-adsystem.com/e/ir?t=daigakuseitus-22&language=ja_JP&l=li3&o=9&a=B07YHJV74T" width="1" height="1" border="0" alt="" style={{
      "border": "none !important",
      "margin": "0px !important"
    }} />
    <p>{`この方法だと`}<strong parentName="p">{`端子を一つ挿すだけでデスクトップの大きなメリットである快適性を享受することができ`}</strong>{`、単純だと思いつつもやはり面倒であるデスクトップとノートでのデータ共有も不要となる。`}</p>
    <p>{`注意点として端子としてはUSB Type-Cでも映像出力の可否、対応レベルに違いがあるため詳細は下記を参照。`}</p>
    <SmallBox type="link" mdxType="SmallBox">
  <Link to="/usb_type-c/" mdxType="Link">USB Type-Cの詳細</Link>
    </SmallBox>
    <h2 {...{
      "id": "ゲームをするならデスクトップ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%B2%E3%83%BC%E3%83%A0%E3%82%92%E3%81%99%E3%82%8B%E3%81%AA%E3%82%89%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97",
        "aria-label": "ゲームをするならデスクトップ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ゲームをするならデスクトップ`}</h2>
    <p>{`高度な3DやVRのゲームをパソコンで楽しみたい場合、グラフィックボードの搭載が必須である。`}</p>
    <p>{`ノートパソコンのグラフィックボードは高価な割にスペックが低く、デスクトップでは10万で楽しめるグラフィックのレベルに20万円近くかかってしまったりする。`}</p>
    <p>{`よって`}<strong parentName="p">{`価格差が大きいという理由により基本的にはデスクトップパソコンをおすすめ`}</strong>{`する。`}</p>
    <p>{`ただ、VRを行う時には場所を確保するためにパソコンを移動させたい、出先で使いたいなど様々な理由によりノートを選ばないとならないケースもあるだろう。その場合は`}<a href="/gpu/">{`GPU性能比較表`}</a>{`などでモバイルの性能を十分にチェックして選ぶことをおすすめする。`}</p>
    <h2 {...{
      "id": "まとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "まとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`まとめ`}</h2>
    <p>{`上記でノートにするかデスクトップにするかを考える上での必要な材料はそろったと思うので、後は自分のライフスタイルを想像して考えてみて欲しい。`}</p>
    <p>{`個人的な意見だが、`}<strong parentName="p">{`家に机があり、８割以上家で使いそうならばデスクトップパソコンを購入`}</strong>{`し、２割はスマホ等で我慢する。または、どうしても必要な場合は格安のノートパソコンを４万円程度で購入してしのぐようにする。`}</p>
    <p>{`ただ、持ち歩きはしないが家の様々な場所で使いたい、どうしても旅行に持っていく必要がある、最高スペックを安価で実現したいなど、`}<strong parentName="p">{`何に重きを置くかはやはりその人次第`}</strong>{`なので予算との兼ね合いもみて考えてみて欲しい。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      